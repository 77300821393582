import { connect }  from 'react-redux'
import { Route }    from 'react-router-dom'
import { useDecodedAccessToken } from '../api/accounts'
import userManager  from '../utils/user-manager'
import LeftNav      from '../components/LeftNav'
import ScreenCenter from '../components/ScreenCenter'
import logo         from '../assets/img/garuda.png'
import { Button }   from '@mui/material'

function signInTo(url) {
  userManager.signinRedirect({ data: { path: url } })
}

function LoginAgain(url) {
  return <Button
    variant='contained'
    color='primary'
    onClick={() => { signInTo(url) }}>
    Login Again
  </Button>
}

function NotLoggedIn({ path, user, loadingUser }){
  const location = window.location.pathname + window.location.search

  if (location.indexOf('redirectUri') >= 0 && !(user?.profile?.sub) && !loadingUser) {
    signInTo(location)
    return <ScreenCenter>Signing in...</ScreenCenter>
  }

  if (!(user?.profile?.sub)) {
    return (
      <ScreenCenter>
        <div>
          <p>You are logged out of Garuda Plex.</p>
          <LoginAgain url={location} />
        </div>
      </ScreenCenter>
    )
  }

  signInTo(location === '/' ? '/general/profile' : location)
  return <ScreenCenter>Redirecting to login...</ScreenCenter>
}

function UnauthorizedAccess() {
  return (
    <ScreenCenter>
      <div>
        <img src={logo} width='320' alt='logo' />
        <p>Your Garuda Plex account does not have access to manage accounts.</p>
        <p>Please contact your Company Administrator to request for access.</p>
        <LoginAgain url='/' />
      </div>
    </ScreenCenter>
  )
}

const ProtectedRoute = ({ user, path, ProtectedComponent, loadingUser }) => {
  const token = useDecodedAccessToken()
  const app_roles = token?.app_roles || {}

  const renderProtectedRoute = () => {
    return user?.profile?.sub
      ? (app_roles?.account?.indexOf('user') >= 0 ||
         app_roles?.account?.indexOf('admin') >= 0
        ? <LeftNav path={path}>
            <ProtectedComponent />
          </LeftNav>
        : <UnauthorizedAccess />
        )
      : <NotLoggedIn path={path} user={user} loadingUser={loadingUser} />
  }

  return <Route exact path={path} render={renderProtectedRoute} />
}

function mapStateToProps(state) {
  return {
    user: state.oidc?.user,
    loadingUser:state.oidc?.isLoadingUser
  }
}

export default connect(mapStateToProps, null)(ProtectedRoute)
