import { createRoot }   from 'react-dom/client'
import { Provider }     from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import AccountsContextProvider from './components/AccountsContextProvider'
import userManager      from './utils/user-manager'
import store            from './utils/store'
import App              from './App'

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <AccountsContextProvider>
        <App />
      </AccountsContextProvider>
    </OidcProvider>
  </Provider>
)
